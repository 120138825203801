<script setup lang="ts">
import MCheckbox from "@mozaic-ds/vue-3/src/components/checkbox/MCheckbox.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { computed, PropType, reactive, ref, watch } from "vue";
import { useStore } from "vuex";

import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import SecretPath from "@/dashboard/views/AppDetails/SecretPath.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";
import { composeNewSecretPath } from "@/dashboard/utils/secret-synchronization-utils";

import { Application } from "@/commons/domain/models/application";
import {
  enableSecretSynchronization,
  getSecretSynchronizationRequestUrl,
} from "@/commons/services/application/application-secret-synchronization.service";

import contents from "@/dashboard/contents/activate-secret-synchronization-modal-layer";

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
});

const emit = defineEmits(["onClose"]);

const store = useStore();

const secretManager = computed((): string => {
  return store.getters["config/secretSynchronizationManager"];
});

const secretSyncRequestUrl = ref(undefined as string);

const form = reactive({
  namespace: "",
  confirmed: false,

  accessError: undefined,

  canBeSubmitted: computed(() => {
    return (
      form.namespace != "" && form.confirmed && form.accessError == undefined
    );
  }),
  canBePublished: computed(() => {
    return form.canBeSubmitted && form.accessError == undefined;
  }),
  validNamespace: computed(() => {
    return form.namespace?.length > 2 && !form.namespace.endsWith("/");
  }),

  warningShake: false,
  animateWarningShake: () => {
    form.warningShake = true;
    setTimeout(() => {
      form.warningShake = false;
    }, 1000);
  },
  onConfirmedClick: () => {
    form.accessError = undefined;
  },
});

const debouncer = new Debouncer();

watch(
  () => form.namespace,
  async () => {
    form.confirmed = false;
    form.accessError = undefined;

    if (form.validNamespace) {
      debouncer.debounce(async () => {
        secretSyncRequestUrl.value = await getSecretSynchronizationRequestUrl(
          props.application.id,
          form.namespace,
        );
      }, 200);
    } else {
      secretSyncRequestUrl.value = undefined;
    }
  },
);

const submit = async () => {
  form.accessError = await enableSecretSynchronization(
    props.application.id,
    form.namespace,
  );

  if (form.accessError == undefined) {
    loadApplication();

    emit("onClose");

    store.commit("postSuccessNotification", {
      title: contents.toastTitle(secretManager.value),
      message: contents.toastMessage(secretManager.value),
    });
  } else {
    form.animateWarningShake();
    form.confirmed = false;
  }
};

const loadApplication = async (): Promise<void> => {
  await store.dispatch("loadCurrentApplication", {
    appId: props.application.id,
  });
};

const currentEnv = computed((): string => {
  return store.getters["config/currentEnvironment"];
});

const calculatedSecretPath = computed(() => {
  return composeNewSecretPath(
    form.namespace,
    props.application.sanitizedName,
    currentEnv.value,
  );
});
</script>

<template>
  <LayerModalFormConfirm
    class="activate-secret-synchronization-modal-layer"
    confirmBtnTheme="solid"
    :confirmBtnLabel="contents.submitBtnLabel"
    :disabledConfirmBtn="!form.canBeSubmitted"
    @submit="submit"
    @close="emit('onClose')"
  >
    <div class="activate-secret-synchronization-modal-layer__form">
      <DvpField
        :label="contents.namespaceLabel(secretManager)"
        :errorMessage="form.accessError"
      >
        <MTextInput
          v-model="form.namespace"
          :placeholder="contents.namespacePlaceholder(secretManager)"
          :isInvalid="form.accessError != undefined"
        />
      </DvpField>

      <SecretPath :secretPath="calculatedSecretPath" />

      <MNotification
        v-if="secretSyncRequestUrl != undefined"
        :class="{
          'activate-secret-synchronization-modal-layer__warning-shake':
            form.warningShake,
        }"
        type="warning"
      >
        <Markdown :content="contents.warningMessage(secretManager)" />
        <template #footer>
          <HelpLink
            :href="secretSyncRequestUrl"
            :label="contents.linkToGiveRights"
            openInNewTab
          />
        </template>
      </MNotification>
    </div>

    <div
      v-if="form.validNamespace"
      class="activate-secret-synchronization-modal-layer__confirmation"
    >
      <MCheckbox
        id="confirmation-checkbox"
        v-model="form.confirmed"
        :label="contents.confirmationText"
        @change="form.onConfirmedClick"
      />
    </div>
  </LayerModalFormConfirm>
</template>

<style lang="scss">
.activate-secret-synchronization-modal-layer {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  padding: 1.5rem 0.5rem;
}

.activate-secret-synchronization-modal-layer__form {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--base-spacing);
}

.activate-secret-synchronization-modal-layer__confirmation {
  display: flex;
  gap: 0.5rem;
}

.activate-secret-synchronization-modal-layer__warning-shake {
  transform: translate3d(0, 0, 0);
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
