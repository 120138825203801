<template>
  <div class="card-wrapper"><slot /></div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.card-wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;

  background-color: var(--color-background-primary);
  border-radius: var(--m-border-radius-medium);
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
}

.card-wrapper > :last-child {
  flex-grow: 1;
}

.card-wrapper > h4 {
  margin-top: 0;
}
</style>
