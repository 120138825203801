import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "access-restriction-selector__restriction-alert" }
const _hoisted_3 = { class: "access-restriction-selector__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MBadge = _resolveComponent("MBadge")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, { class: "access-restriction-selector" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.privacies, (privacy) => {
        return (_openBlock(), _createElementBlock("div", {
          key: privacy.value,
          class: _normalizeClass(["access-restriction-selector__card", {
        'access-restriction-selector__card--current':
          $props.selectedPrivacy === privacy.value,
        'access-restriction-selector__card--disabled': privacy.isDisabled,
        'access-restriction-selector__card--enabled': !privacy.isDisabled,
      }])
        }, [
          _createElementVNode("div", {
            class: "stack-layout text-centered",
            onClick: ($event: any) => ($options.changePrivacy(privacy))
          }, [
            _createElementVNode("div", _hoisted_2, [
              (
              privacy.value === $data.EPrivacyName.PUBLIC &&
              $props.apiHasPendingChangeToPublic
            )
                ? (_openBlock(), _createBlock(_component_MBadge, {
                    key: 0,
                    type: "warning"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($data.contents.pendingRequest), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(privacy.icon)))
            ]),
            _createElementVNode("strong", null, _toDisplayString(privacy.label), 1),
            _createElementVNode("p", null, _toDisplayString(privacy.description), 1)
          ], 8, _hoisted_1)
        ], 2))
      }), 128))
    ]),
    _: 1
  }))
}