<template>
  <Grid class="access-restriction-selector">
    <div
      v-for="privacy in privacies"
      :key="privacy.value"
      class="access-restriction-selector__card"
      :class="{
        'access-restriction-selector__card--current':
          selectedPrivacy === privacy.value,
        'access-restriction-selector__card--disabled': privacy.isDisabled,
        'access-restriction-selector__card--enabled': !privacy.isDisabled,
      }"
    >
      <div class="stack-layout text-centered" @click="changePrivacy(privacy)">
        <div class="access-restriction-selector__restriction-alert">
          <MBadge
            v-if="
              privacy.value === EPrivacyName.PUBLIC &&
              apiHasPendingChangeToPublic
            "
            type="warning"
            >{{ contents.pendingRequest }}</MBadge
          >
        </div>
        <div class="access-restriction-selector__icon">
          <component :is="privacy.icon" />
        </div>
        <strong>{{ privacy.label }}</strong>
        <p>{{ privacy.description }}</p>
      </div>
    </div>
  </Grid>
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { markRaw } from "vue";

import Card from "@/commons/components/Card.vue";
import Grid from "@/commons/components/Grid.vue";

import IconPrivacyInternal from "./IconPrivacyInternal.vue";
import IconPrivacyPartners from "./IconPrivacyPartners.vue";
import IconPrivacyPrivate from "./IconPrivacyPrivate.vue";
import IconPrivacyPublic from "./IconPrivacyPublic.vue";

import { EPrivacyName } from "@/commons/store/types";

import contents from "@/manager/contents/access-restriction-selector";

export default {
  components: {
    MBadge,
    Grid,
    Card,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    selectedPrivacy: {
      type: String,
      validator: (value) => {
        return !!EPrivacyName[value];
      },
    },
    apiHasPendingChangeToPublic: {
      type: Boolean,
      default: false,
    },
    apiPublicAccessRestriction: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["change"],
  data() {
    return {
      contents,
      EPrivacyName,
      privacies: [
        {
          value: EPrivacyName.PRIVATE,
          label: contents.privacies[EPrivacyName.PRIVATE].label,
          description: contents.privacies[EPrivacyName.PRIVATE].description,
          icon: markRaw(IconPrivacyPrivate),
          isDisabled: false,
        },
        {
          value: EPrivacyName.INTERNAL,
          label: contents.privacies[EPrivacyName.INTERNAL].label,
          description: contents.privacies[EPrivacyName.INTERNAL].description,
          icon: markRaw(IconPrivacyInternal),
          isDisabled: false,
        },
        {
          value: EPrivacyName.PARTNER,
          label: contents.privacies[EPrivacyName.PARTNER].label,
          isDisabled: !this.apiPublicAccessRestriction,
          description: !this.apiPublicAccessRestriction
            ? contents.apiShouldBeAccessibleOnInternet
            : contents.privacies[EPrivacyName.PARTNER].description,
          icon: markRaw(IconPrivacyPartners),
        },
        {
          value: EPrivacyName.PUBLIC,
          label: contents.privacies[EPrivacyName.PUBLIC].label,
          isDisabled: !this.apiPublicAccessRestriction,
          description: !this.apiPublicAccessRestriction
            ? contents.apiShouldBeAccessibleOnInternet
            : contents.privacies[EPrivacyName.PUBLIC].description,
          icon: markRaw(IconPrivacyPublic),
        },
      ],
    };
  },
  methods: {
    async changePrivacy(newPrivacy) {
      if (newPrivacy.isDisabled || newPrivacy === this.selectedPrivacy) {
        return;
      }
      this.$emit("change", newPrivacy.value);
    },
  },
};
</script>

<style lang="scss">
.access-restriction-selector {
  --grid-layout-num-columns: 4;
  --grid-layout-item-width: 1rem;
}
.access-restriction-selector__restriction-alert {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: right;
}

.access-restriction-selector__icon {
  height: 69px;
}

.access-restriction-selector__card {
  padding: var(--box-layout-space, 1rem);

  background-color: var(--color-background-interface);
  border: 3px solid transparent;
  border-radius: 0.5rem;
}

.access-restriction-selector__card--current {
  background-color: var(--color-background-primary);
  border: var(--border-primary-bold);

  svg:not(.icon-restriction-internal) path {
    fill: var(--color-icon-primary);
  }
}

.access-restriction-selector__card--enabled:hover {
  cursor: pointer;
  border: var(--border-primary-bold);
  transition: border 0.4s ease-in-out;

  svg:not(.icon-restriction-internal) path {
    fill: var(--color-icon-primary);
  }
}

.access-restriction-selector__card--disabled {
  color: var(--color-text-minor);
  cursor: not-allowed;
  background-color: var(--color-background-disabled);
}
</style>
