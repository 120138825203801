export const composeNewSecretPath = (
  namespace: string,
  applicationSanitizedName: string,
  env: string,
) => {
  return `${namespace}/_api-gateway/${applicationSanitizedName}/${env?.toUpperCase()}`;
};

export const composeSavedSecretPath = (
  namespace: string,
  savedSecretPath: string,
) => {
  return `${namespace}/${savedSecretPath}`;
};
