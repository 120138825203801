<template>
  <MDataTableV2
    class="consumers-api-table"
    :headers="tableHeaders"
    :items="contractsList"
    pageable
    :currentPage="pagedContracts.currentPage"
    :totalItems="pagedContracts.totalCount"
    :itemsPerPageOptions="[SIZE_PER_PAGE_CONSUMERS]"
    :itemsPerPage="SIZE_PER_PAGE_CONSUMERS"
    @update:currentPage="$emit('update:currentPage', $event)"
  >
    <template #no-data>
      <div class="consumers-api-table__no-data">
        {{ contents.noConsumerFound }}
      </div>
    </template>
    <template #[`cell.name`]="{ item: contract }">
      <span data-cy="consumer-application-name">
        <RouterLink
          v-if="userIsAdmin"
          :to="{
            name: 'application',
            params: { id: contract.application.id },
          }"
        >
          {{ contract.application.name }}
        </RouterLink>
        <template v-else>
          {{ contract.application.name }}
        </template>
      </span>
    </template>
    <template #[`cell.oauthFlow`]="{ item: contract }">
      <div class="consumers-api-table__oauth-flow">
        <img
          class="consumers-api-table__oauth-flow-icon"
          :src="getOauthFlowIcon(contract)"
        />
        <IconTooltip :text="getOauthFlowTooltipText(contract)" />
      </div>
    </template>
    <template #[`cell.oauthCredentials`]="{ item: contract }">
      <!-- OAUTH token status for any consumer is based on contract status -->
      <DvpToggle
        :checked="contract.status === EContractStatus.ENABLED"
        :disabled="!hasWriteAccessOnCurrentApi"
        :data-cy="`oauth-token-status-${contract.id}`"
        @click="onClickOnContractStatusToggle(contract)"
      />
    </template>
    <template #[`cell.apiKeyCredentials`]="{ item: contract }">
      <ul class="consumers-api-table__api-key-credentials-list">
        <li
          v-for="token in contract.tokens"
          :key="token.id"
          class="consumers-api-table__api-key-credentials-item api-key-credentials-item"
        >
          <DvpToggle
            :checked="token.status === ETokenStatus.ENABLED"
            :disabled="!hasWriteAccessOnCurrentApi"
            :data-cy="`api-key-status-${token.id}`"
            @click="onClickOnTokenStatusToggle(contract, token)"
          />
          <div class="api-key-credentials-item__key-and-expiration">
            <span
              class="consumers-api-table__credentials-key"
              :data-cy="`api-key-${token.id}`"
            >
              {{ token.key }}
            </span>
            <div class="api-key-credentials-item__expiration-date">
              {{ contents.expirationDate }}
              {{ toLocaleDate(token.expireAt) }}
            </div>
          </div>
        </li>
      </ul>
    </template>
    <template #[`cell.consumerIPs`]="{ item: contract }">
      <ButtonIconInfo
        v-if="applicationHasSetIPs(contract.application)"
        :label="contents.ipsLabel"
        @click="openIPsModal(contract)"
      />
    </template>
    <template #[`cell.applicationManagers`]="{ item: contract }">
      <ButtonIconInfo
        v-if="getGroupsCount(contract.application) > 0"
        :label="contents.managersLabel"
        @click="openApplicationManagersModal(contract.application)"
      />
      <span v-else>{{ contents.noManagerOnApplication }}</span>
    </template>
  </MDataTableV2>
</template>

<script lang="ts">
import MDataTableV2 from "@mozaic-ds/vue-3/src/components/datatablev2/MDataTableV2.vue";
import { markRaw, PropType } from "vue";

import DvpToggle from "@/commons/components/DvpToggle.vue";
import IconTooltip from "@/commons/components/IconTooltip.vue";
import ApplicationManagersModal from "@/manager/views/ApiDetailConsumers/ApplicationManagersModal.vue";
import ButtonIconInfo from "@/manager/views/ApiDetailConsumers/ButtonIconInfo.vue";
import ConsumersIPsModal from "@/manager/views/ApiDetailConsumers/ConsumerIPsModal.vue";
import ConsumersDeactivationModal from "@/manager/views/ApiDetailConsumers/ConsumersDeactivationModal.vue";

import {
  getContractOauthFlow,
  isOAuthContract,
  SIZE_PER_PAGE_CONSUMERS,
} from "@/commons/libs/utils/contractUtils";
import { userHasWriteAccessOnApi } from "@/commons/utils/api-utils";
import { toLocaleDate } from "@/commons/utils/date-utils";
import { OAUTH_FLOW_ICON } from "@/manager/utils/oauth-flow-items";

import { Api } from "@/commons/domain/models/api";
import { Application } from "@/commons/domain/models/application";
import { Contract } from "@/commons/domain/models/contract";
import { MDataTableV2HeaderItem } from "@/commons/domain/models/mozaic";
import { PagedResource } from "@/commons/domain/models/paged-resource";
import { Token } from "@/commons/domain/models/token";
import { EOauthFlow } from "@/commons/types/oauth-flow-types";

import {
  EApplicationType,
  EAuthType,
  EContractStatus,
  ETokenStatus,
} from "@/commons/store/types";

import contentsOfApplicationManagersModal from "@/manager/contents/application-managers-modal";
import contentsOfConsumersIPsModal from "@/manager/contents/consumer-ips-modal";
import contents from "@/manager/contents/consumers-api";
import consumersDeactivationModalContents from "@/manager/contents/consumers-deactivation-modal";

export default {
  components: {
    ButtonIconInfo,
    IconTooltip,
    DvpToggle,
    MDataTableV2,
  },
  props: {
    pagedContracts: {
      type: Object as PropType<PagedResource<Contract>>,
      required: true,
    },
    currentTab: {
      type: String,
      required: true,
    },
  },
  emits: ["update:currentPage"],
  data() {
    return {
      SIZE_PER_PAGE_CONSUMERS,
      contents,
      ETokenStatus,
      EContractStatus,
    };
  },
  computed: {
    userIsAdmin(): boolean {
      return this.$store.getters["userIsAdmin"];
    },
    currentApi(): Api {
      return this.$store.getters["currentApi"];
    },
    hasWriteAccessOnCurrentApi(): boolean {
      return userHasWriteAccessOnApi(this.currentApi);
    },
    tableHeaders(): MDataTableV2HeaderItem[] {
      const headers: MDataTableV2HeaderItem[] = [];

      headers.push({
        label: contents.headerApplicationName,
        value: "name",
      });

      if (this.currentApi.authType === EAuthType.OAUTH) {
        headers.push(
          {
            label: contents.headerOauthFlow,
            value: "oauthFlow",
          },
          {
            label: contents.headerCredentials,
            value: "oauthCredentials",
          },
        );
      } else {
        headers.push({
          label: contents.headerCredentials,
          value: "apiKeyCredentials",
        });
      }

      if (this.displayConsumerIPsColumn) {
        headers.push({
          label: contents.headerConsumerIPs,
          value: "consumerIPs",
        });
      }

      headers.push({
        label: contents.headerApplicationManager,
        value: "applicationManagers",
      });

      return headers;
    },
    contractsList() {
      return Object.values(this.pagedContracts.data);
    },
    displayConsumerIPsColumn(): boolean {
      return (
        this.currentTab !== EApplicationType.SANDBOX &&
        this.currentApi.isIPFilteringEnabled
      );
    },
  },
  methods: {
    toLocaleDate,
    isOAuthContract,
    applicationHasSetIPs(application: Application): boolean {
      return application.ipFiltering?.ipRanges.length > 0;
    },
    getGroupsCount(application: Application): number {
      return application.acl ? Object.values(application.acl.groups).length : 0;
    },
    getOauthFlowIcon(contract: Contract): string {
      return OAUTH_FLOW_ICON[getContractOauthFlow(contract)];
    },
    getOauthFlowTooltipText(contract: Contract): string {
      return getContractOauthFlow(contract) === EOauthFlow.CLIENT_CREDENTIALS
        ? contents.tooltipOauthCcf
        : contents.tooltipOauthAcf;
    },
    openApplicationManagersModal(application: Application): void {
      this.$store.commit("openLayerModal", {
        title: contentsOfApplicationManagersModal.modalTitle,
        component: markRaw(ApplicationManagersModal),
        props: { groups: Object.values(application.acl.groups) },
      });
    },
    openIPsModal(contract: Contract): void {
      this.$store.commit("openLayerModal", {
        title: contentsOfConsumersIPsModal.modalTitle,
        component: markRaw(ConsumersIPsModal),
        props: { application: contract.application },
      });
    },
    onClickOnTokenStatusToggle(contract: Contract, token: Token): void {
      if (
        token.status === ETokenStatus.DISABLED ||
        token.status === ETokenStatus.PENDING
      ) {
        this.$store.dispatch("enableToken", {
          contractId: contract.id,
          appId: contract.application.id,
          tokenId: token.id,
          apiId: this.currentApi.id,
        });
      } else {
        this.openDeactivationModal(contract, token);
      }
    },
    onClickOnContractStatusToggle(contract: Contract): void {
      if (contract.status === EContractStatus.ENABLED) {
        this.openDeactivationModal(contract);
      } else {
        this.$store.dispatch("enableContract", {
          appId: contract.application.id,
          contractId: contract.id,
        });
      }
    },
    openDeactivationModal(contract: Contract, token?: Token): void {
      this.$store.commit("openModal", {
        title: consumersDeactivationModalContents.consumersDeactivationTitle,
        component: markRaw(ConsumersDeactivationModal),
        props: {
          contract: contract,
          tokenId: token?.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.consumers-api-table__oauth-flow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.consumers-api-table__oauth-flow-icon {
  height: 1.5rem;
}

.consumers-api-table__api-key-credentials-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0; // because ul element adds a big left padding
}

.consumers-api-table__api-key-credentials-item {
  display: flex;
}

.consumers-api-table__expiration-date {
  @include set-text-xs;
}

.consumers-api-table__credentials-key {
  color: var(--color-text-minor);
}
</style>
