<template>
  <div class="item" :class="{ item__selected: selected }">
    <div class="item__action--left">
      <span>
        <MIcon name="CommentComment24" />
      </span>
    </div>

    <div class="item__content">
      <div class="item__title request__description">
        {{ computeLabel(request) }}
      </div>
      <div
        v-if="request.applicationName"
        class="item__subtitle request__application"
      >
        {{ contents.forApplicationLabel }}: {{ request.applicationName }}
      </div>
      <div class="item__subtitle request__author">
        By:
        {{
          mine
            ? "you"
            : request.author != undefined
              ? request.author.name
              : "Unknown user"
        }}
      </div>
    </div>

    <div v-if="request.conversationCount" class="item__action--middle">
      <span class="request__count"> [{{ request.conversationCount }}] </span>
    </div>

    <div class="item__action--right">
      <span class="request__date">
        {{ requestDate(request.createdAt) }}
      </span>
      <request-status :request="request" />
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import RequestStatus from "@/request-manager/views/RequestStatus.vue";

import { requestDate } from "@/commons/libs/utils/dateUtils";

import requestManagerContents from "@/request-manager/contents/request-manager-contents.js";
import { requestLabel } from "@/request-manager/contents/request-manager-utils";

export default {
  name: "RequestManagerListItem",
  components: { RequestStatus, MIcon },
  props: {
    request: {
      type: Object,
      required: true,
    },
    mine: {
      type: Boolean,
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
    },
  },
  data: function () {
    return {
      contents: requestManagerContents.ui,
    };
  },
  methods: {
    requestDate,
    computeLabel(request) {
      return requestLabel(request);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/commons/assets/styles/vars";

.request {
  &__application {
    @include set-text-s;
  }
  &__author {
    @include set-text-s;
    font-style: italic;
  }
  &__date {
    @include set-text-xs;
  }
}

.item {
  display: flex;
  width: 100%;
  padding: 0 1.286rem;
  margin-bottom: 0.143rem;
  cursor: pointer;
  background: var(--color-background-interface);
  border-radius: 4px;
  &__selected {
    color: var(--color-white);
    background-color: var(--color-background-blue);
  }

  &:hover:not(&__selected) {
    background: rgb(var(--color-primary-rgba) 0.1);
  }

  &__action--left {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1.286rem;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 2;
    padding: 0.625rem 0;
    overflow: hidden;
  }

  &__action--middle {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    align-self: center;
    min-width: 1.714rem;
  }

  &__action--right {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-end;
    align-self: flex-start;
    min-width: 10rem;
    max-width: 25%;
    margin: 0.786rem 0;
  }

  &__title {
    margin-bottom: 0.143rem;
  }
  &__subtitle {
    @include set-text-s;
    flex: 1 1 100%;
    margin-bottom: 0.35rem;
    overflow: hidden;
    line-height: 1.3;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
