import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3d6323c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item__action--left" }
const _hoisted_2 = { class: "item__content" }
const _hoisted_3 = { class: "item__title request__description" }
const _hoisted_4 = {
  key: 0,
  class: "item__subtitle request__application"
}
const _hoisted_5 = { class: "item__subtitle request__author" }
const _hoisted_6 = {
  key: 0,
  class: "item__action--middle"
}
const _hoisted_7 = { class: "request__count" }
const _hoisted_8 = { class: "item__action--right" }
const _hoisted_9 = { class: "request__date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_request_status = _resolveComponent("request-status")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item", { item__selected: $props.selected }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, [
        _createVNode(_component_MIcon, { name: "CommentComment24" })
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($options.computeLabel($props.request)), 1),
      ($props.request.applicationName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.contents.forApplicationLabel) + ": " + _toDisplayString($props.request.applicationName), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, " By: " + _toDisplayString($props.mine
            ? "you"
            : $props.request.author != undefined
              ? $props.request.author.name
              : "Unknown user"), 1)
    ]),
    ($props.request.conversationCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, " [" + _toDisplayString($props.request.conversationCount) + "] ", 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("span", _hoisted_9, _toDisplayString($options.requestDate($props.request.createdAt)), 1),
      _createVNode(_component_request_status, { request: $props.request }, null, 8, ["request"])
    ])
  ], 2))
}