<template>
  <ul
    :class="[
      'settings-menu',
      'stack-layout',
      'weight-light',
      { 'settings-menu--collapsed': isCollapsed },
    ]"
  >
    <li>
      <RouterLink
        data-cy="navigate-before-button"
        :to="previousPageRoute"
        class="settings-menu__item"
      >
        <div class="settings-menu__label-wrapper">
          <MIcon name="ArrowBack24" />
          <span class="settings-menu__label">{{
            contents.backToApisList
          }}</span>
        </div>
      </RouterLink>
    </li>

    <li v-for="menuItem in menu" :key="menuItem.id">
      <RouterLink
        :to="menuItem.path"
        :class="[
          'settings-menu__item',
          { 'settings-menu__item--selected': menuItem.id === menuId },
        ]"
      >
        <template v-if="!isLoading">
          <i
            v-if="menuItem.required"
            class="settings-menu__alert settings-menu__alert--danger"
          />
          <i
            v-else-if="menuItem.warning"
            class="settings-menu__alert settings-menu__alert--warning"
          />
        </template>
        <Tooltip
          :tooltipContent="menuItem.label"
          :is-disabled="!isCollapsed"
          position="right"
        >
          <div class="settings-menu__label-wrapper">
            <MIcon v-if="menuItem.mozaicIcon" :name="menuItem.mozaicIcon" />
            <component
              :is="menuItem.iconComponent"
              v-else-if="menuItem.iconComponent"
              class="settings-menu__icon-component"
            />
            <span class="settings-menu__label" :data-cy="menuItem.label">
              {{ menuItem.label }}
            </span>
          </div>
        </Tooltip>
      </RouterLink>
    </li>

    <li class="split-after"></li>

    <li class="settings-menu__toggle">
      <div @click="toggleCollapsed">
        <span v-if="isCollapsed">
          <MIcon name="ArrowNext32" />
        </span>
        <span v-else>
          <MIcon name="ArrowBack32" />
        </span>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import IconButton from "@/commons/components/IconButton.vue";
import Tooltip from "@/commons/components/Tooltip/Tooltip.vue";

import IconBack from "./IconBack.vue";
import IconPublication from "./IconPublication.vue";
import IconRouting from "./IconRouting.vue";

import {
  API_MANAGER_ROUTE_NAME,
  getPreviousPageRoute,
} from "@/commons/utils/route-utils";

const contents = {
  backToApisList: "Back to APIs list",
};

export default {
  name: "SettingsMenu",
  components: {
    IconBack,
    Tooltip,
    MIcon,
    IconButton,
  },
  props: {
    menuId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contents,
      isCollapsed: false,
      previousPageRoute: getPreviousPageRoute(API_MANAGER_ROUTE_NAME),
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    menu() {
      const apiHasRoutes = this.$store.getters["apiHasRoutes"];
      const apiMissingRecommendedPageTypes =
        this.$store.getters["apiMissingRecommendedPageTypes"];
      const apiMissingRequiredPageTypes =
        this.$store.getters["apiMissingRequiredPageTypes"];
      const apiHasQuota = this.$store.getters["apiHasQuota"];
      const apiHasPublishedRoutes =
        this.$store.getters["apiHasPublishedRoutes"];

      let routes = [
        {
          mozaicIcon: "DisplaySetting24",
          label: "Settings",
          path: "settings",
          id: "settings",
        },
        {
          mozaicIcon: "CustomCopyKey24",
          label: "Authentication",
          path: "authentication",
          id: "authentication",
        },
        {
          iconComponent: IconRouting,
          label: "Routing",
          path: "routing",
          required: !apiHasRoutes,
          id: "routing",
        },
        {
          mozaicIcon: "Security24",
          label: "Access restriction",
          path: "access-restriction",
          id: "access-restriction",
        },
        {
          mozaicIcon: "ListAdd24",
          label: "Documentation",
          path: "documentation",
          warning: apiMissingRecommendedPageTypes?.length > 0,
          required: apiMissingRequiredPageTypes?.length > 0,
          id: "documentation",
        },
        {
          mozaicIcon: "Gauge24",
          label: "Quotas",
          path: "quotas",
          warning: !apiHasQuota,
          id: "quotas",
        },
        {
          mozaicIcon: "Group24",
          label: "Consumers",
          path: "consumers",
          id: "consumers",
        },
        {
          mozaicIcon: "Policies24",
          label: "Policies",
          path: "policies",
          id: "policies",
        },
        {
          iconComponent: IconPublication,
          label: "Publication",
          path: "publication",
          id: "publication",
          required: !apiHasPublishedRoutes,
        },
      ];

      // filters routes
      const apiAllowRateLimit = this.$store.getters["apiAllowRateLimit"];
      if (!apiAllowRateLimit) {
        routes = routes.filter((route) => route.id !== "quotas");
      }

      return routes;
    },
  },
  methods: {
    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style lang="scss">
.settings-menu {
  --stack-layout-glutter: 0;
  height: 100%;
  padding: 0;
  margin: 0;
  color: var(--color-grey);
  list-style: none;
  background-color: var(--color-white);
  border-right: var(--border-default);
  fill: var(--color-grey);

  a {
    text-decoration: none;
  }

  a:hover {
    cursor: pointer;
  }

  .settings-menu__item {
    position: relative;
    display: flex;
    padding: 1rem 1.5rem;
  }

  .settings-menu__label-wrapper {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  .settings-menu__icon-component {
    width: 27px;
    height: 27px;
    fill: currentcolor;
  }

  .settings-menu__label {
    display: none;
    white-space: nowrap;
  }

  .settings-menu__item.settings-menu__item--selected {
    color: var(--color-primary);
    fill: var(--color-primary);

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 6px;
      height: 100%;
      content: "";
      background-color: var(--color-primary);
    }
  }

  .settings-menu__toggle {
    display: none;
    padding: 1rem;
    text-align: right;
    cursor: pointer;
    border-top: var(--border-default);
  }

  .tooltip:hover .tooltip__content {
    display: block;
  }

  .settings-menu__alert {
    position: absolute;
    top: 3px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    font-size: 8px;
    font-weight: 700;
    line-height: 10px;
    border-radius: 50%;

    &--danger {
      background-color: var(--color-border-danger);
    }

    &--warning {
      background-color: var(--color-border-warning);
    }
  }

  @media screen and (width >= 72em) {
    .settings-menu__label {
      display: inline;
    }

    .settings-menu__toggle {
      display: block;
    }

    .tooltip.tooltip--disabled .tooltip__content {
      display: none;
    }
  }
}

.settings-menu.settings-menu--collapsed .settings-menu__label {
  display: none;
}
</style>
