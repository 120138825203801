module.exports = {
  modalTitle: "Create a new application",
  appWithSameNameWarningTitle: "Application already exists",
  appWithSameNameWarningMessage: (appName) =>
    `You already own an application named ${appName}`,
  goToApplicationLinkTitle: "Go to application",
  applicationDescLabel: "Description",
  applicationForTestingTooltip:
    "You will not be able to set a related product for a test application.",
  applicationName: "Application name",
  applicationProductPlaceholder: "-- Search a related product --",
  applicationProductTooltip:
    "If you cannot find your product, make sure it exists in Tangram.",
  applicationType: "Application type",
  applicationTypeLabel: "Select an application type",
  applicationTypes: {
    IOS: "iOS Application",
    ANDROID: "Android Application",
    WEB: "Web Application",
    APPLICATION_SERVER: "Application server",
  },
  relatedProduct: "Related product",
  testApplication: "This is a test application",
  createErrorMessage: "Unable to create the application",
  createButtonLabel: "Create application",
  updateButtonLabel: "Apply change",
  addManagerGroupPlaceholder: "-- Add a manager group --",
  managerGroup: "Manager group",
  managerGroupTooltip:
    "Choose the group responsible for the application. You can find the list of groups where you are a manager.",
  description: "Description",
  linkToGroupsSettings: "Click here to manage groups",
  cancel: "Cancel",

  unableToUpdateAppForTesting:
    "You cannot switch this Application to a test one while the Vault Publication is activated",
  disabledNameTooltip:
    "You cannot change the name of the application while the secrets synchronization is activated",
};
