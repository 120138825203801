<template>
  <CardLink
    data-type="card-api-summary"
    :data-id="api.id"
    class="card-api-summary"
    :to="{ name: 'managerApiDetailSettings', params: { id: api.id } }"
  >
    <CardContentApi
      :name="apiLabel"
      :apiType="api.type"
      :version="api.version"
      :description="api.description"
      :product-name="!hideProduct && api.product && api.product.name"
    />
    <template #footer
      ><CardFooterApi>
        <AuthorizationRequired
          v-if="authorizationRequired"
          class="card-api-summary--margin-top-bottom"
        />
        <div v-if="isDeprecated" class="card-api-summary--margin-top-bottom">
          <Chip>DEPRECATED</Chip>
        </div>
        <ZonesList
          data-cy="api-published-zones"
          :zones="enabledZonesNames"
          class="card-api-summary--margin-top-bottom"
        />
        <BusinessUnitDeployment
          v-if="isLogged"
          :tags="buTags"
          :label="businessUnitsLabel"
          class="card-api-summary--margin-top-bottom"
        />
      </CardFooterApi>
    </template>
  </CardLink>
</template>

<script lang="ts">
import { apiHasAuthorizationRequired } from "./api-has-authorization-required";
import { getProductNameFromApi } from "./get-product-name-from-api";

import AuthorizationRequired from "@/commons/components/AuthorizationRequired/AuthorizationRequired.vue";
import BusinessUnitDeployment from "@/commons/components/BusinessUnitDeployment/BusinessUnitDeployment.vue";
import CardContentApi from "@/commons/components/CardListed/CardContentApi.vue";
import CardFooterApi from "@/commons/components/CardListed/CardFooterApi.vue";
import CardLink from "@/commons/components/CardListed/CardLink.vue";
import Chip from "@/commons/components/Chip.vue";
import ZonesList from "@/commons/components/ZonesList/ZonesList.vue";

import {
  getApiRestLabel,
  getApiRestTypeLabel,
  getTopicLabel,
} from "@/commons/libs/utils/apiUtils";
import { filterBuTags } from "@/commons/libs/utils/businessUnitsUtils";

import { EApiType } from "@/commons/store/types";

export default {
  name: "CardApiSummary",
  components: {
    CardLink,
    CardContentApi,
    CardFooterApi,
    AuthorizationRequired,
    Chip,
    ZonesList,
    BusinessUnitDeployment,
  },
  props: {
    api: {
      type: Object,
      required: true,
    },
    hideProduct: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    zones() {
      return this.$store.getters["zones"];
    },
    productName() {
      return getProductNameFromApi(this.api, this.hideProduct);
    },
    authorizationRequired() {
      return apiHasAuthorizationRequired(this.api);
    },
    isDeprecated() {
      return this.api.isDeprecated;
    },
    enabledZonesNames() {
      let zonesNames = this.api && this.api.deployedZoneNames;
      if (zonesNames && zonesNames.length > 0) {
        return zonesNames;
      } else {
        return [];
      }
    },
    isLogged() {
      return this.$store.getters["userIsLogged"];
    },
    buTags() {
      return filterBuTags(this.api.tags);
    },
    apiLabel() {
      return this.api?.type === EApiType.API_REST
        ? getApiRestLabel(this.api)
        : getTopicLabel(this.api);
    },
    businessUnitsLabel() {
      return getApiRestTypeLabel(this.api.internal);
    },
  },
};
</script>

<style lang="scss">
.card-api-summary {
  background-color: var(--color-background-primary);
}
.card-api-summary--margin-top-bottom {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
