export enum ENotificationToastType {
  // enum values match with ToastType values for ease of use
  ERROR = "danger",
  SUCCESS = "success",
  INFO = "information",
  WARNING = "warning",
}

export interface NotificationContent {
  title?: string;
  message?: string;
  link?: NotificationLink;
  type?: ENotificationToastType;
  component?: any;
  props?: any;
}

export interface NotificationToast {
  id?: string;
  title?: string;
  message?: string;
  type?: ENotificationToastType;
  autoClosable?: boolean;
  link?: NotificationLink;
  component?: any;
  props?: any;
}

export interface NotificationLink {
  label: string;
  href: string;
  openInNewTab?: boolean;
}

export interface State {
  notificationToasts: NotificationToast[];
}
