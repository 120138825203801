import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "consumers-api-table__no-data" }
const _hoisted_2 = { "data-cy": "consumer-application-name" }
const _hoisted_3 = { class: "consumers-api-table__oauth-flow" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "consumers-api-table__api-key-credentials-list" }
const _hoisted_6 = { class: "api-key-credentials-item__key-and-expiration" }
const _hoisted_7 = ["data-cy"]
const _hoisted_8 = { class: "api-key-credentials-item__expiration-date" }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_IconTooltip = _resolveComponent("IconTooltip")!
  const _component_DvpToggle = _resolveComponent("DvpToggle")!
  const _component_ButtonIconInfo = _resolveComponent("ButtonIconInfo")!
  const _component_MDataTableV2 = _resolveComponent("MDataTableV2")!

  return (_openBlock(), _createBlock(_component_MDataTableV2, {
    class: "consumers-api-table",
    headers: $options.tableHeaders,
    items: $options.contractsList,
    pageable: "",
    currentPage: $props.pagedContracts.currentPage,
    totalItems: $props.pagedContracts.totalCount,
    itemsPerPageOptions: [$data.SIZE_PER_PAGE_CONSUMERS],
    itemsPerPage: $data.SIZE_PER_PAGE_CONSUMERS,
    "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:currentPage', $event)))
  }, {
    "no-data": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString($data.contents.noConsumerFound), 1)
    ]),
    [`cell.name`]: _withCtx(({ item: contract }) => [
      _createElementVNode("span", _hoisted_2, [
        ($options.userIsAdmin)
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 0,
              to: {
            name: 'application',
            params: { id: contract.application.id },
          }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(contract.application.name), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(contract.application.name), 1)
            ], 64))
      ])
    ]),
    [`cell.oauthFlow`]: _withCtx(({ item: contract }) => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "consumers-api-table__oauth-flow-icon",
          src: $options.getOauthFlowIcon(contract)
        }, null, 8, _hoisted_4),
        _createVNode(_component_IconTooltip, {
          text: $options.getOauthFlowTooltipText(contract)
        }, null, 8, ["text"])
      ])
    ]),
    [`cell.oauthCredentials`]: _withCtx(({ item: contract }) => [
      _createVNode(_component_DvpToggle, {
        checked: contract.status === $data.EContractStatus.ENABLED,
        disabled: !$options.hasWriteAccessOnCurrentApi,
        "data-cy": `oauth-token-status-${contract.id}`,
        onClick: ($event: any) => ($options.onClickOnContractStatusToggle(contract))
      }, null, 8, ["checked", "disabled", "data-cy", "onClick"])
    ]),
    [`cell.apiKeyCredentials`]: _withCtx(({ item: contract }) => [
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contract.tokens, (token) => {
          return (_openBlock(), _createElementBlock("li", {
            key: token.id,
            class: "consumers-api-table__api-key-credentials-item api-key-credentials-item"
          }, [
            _createVNode(_component_DvpToggle, {
              checked: token.status === $data.ETokenStatus.ENABLED,
              disabled: !$options.hasWriteAccessOnCurrentApi,
              "data-cy": `api-key-status-${token.id}`,
              onClick: ($event: any) => ($options.onClickOnTokenStatusToggle(contract, token))
            }, null, 8, ["checked", "disabled", "data-cy", "onClick"]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", {
                class: "consumers-api-table__credentials-key",
                "data-cy": `api-key-${token.id}`
              }, _toDisplayString(token.key), 9, _hoisted_7),
              _createElementVNode("div", _hoisted_8, _toDisplayString($data.contents.expirationDate) + " " + _toDisplayString($options.toLocaleDate(token.expireAt)), 1)
            ])
          ]))
        }), 128))
      ])
    ]),
    [`cell.consumerIPs`]: _withCtx(({ item: contract }) => [
      ($options.applicationHasSetIPs(contract.application))
        ? (_openBlock(), _createBlock(_component_ButtonIconInfo, {
            key: 0,
            label: $data.contents.ipsLabel,
            onClick: ($event: any) => ($options.openIPsModal(contract))
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    [`cell.applicationManagers`]: _withCtx(({ item: contract }) => [
      ($options.getGroupsCount(contract.application) > 0)
        ? (_openBlock(), _createBlock(_component_ButtonIconInfo, {
            key: 0,
            label: $data.contents.managersLabel,
            onClick: ($event: any) => ($options.openApplicationManagersModal(contract.application))
          }, null, 8, ["label", "onClick"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($data.contents.noManagerOnApplication), 1))
    ]),
    _: 2
  }, 1032, ["headers", "items", "currentPage", "totalItems", "itemsPerPageOptions", "itemsPerPage"]))
}