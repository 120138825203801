<template>
  <div class="product-list-item">
    <div class="product-list-item__header">
      <img
        class="product-list-item__image"
        :alt="product.name"
        :src="productIcon"
      />
    </div>

    <div class="product-list-item__body">
      <div class="product-list-item__content">
        <div class="product-list-item__title">
          {{ product.name }}
        </div>
        <div class="product-list-item__description">
          {{ product.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";

import { Product } from "@/commons/domain/models/product";

const defaultIconPath = require("@/commons/assets/icons/default-product-icon.svg");

export default {
  name: "ProductListItem",
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  computed: {
    productIcon() {
      return this.product.logoUrl ? this.product.logoUrl : defaultIconPath;
    },
  },
};
</script>

<style lang="scss">
.product-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 23rem;
  height: 21rem;
  padding: 1.5rem;
  background-color: var(--color-white);
  border: var(--border-default);
  border-radius: var(--m-border-radius-medium);
}

.product-list-item__header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
  margin-bottom: 2rem;
}

.product-list-item__image {
  width: 100%;
  max-height: 100%;
}

.product-list-item__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.product-list-item__title {
  margin-bottom: 0.5rem;
  font-weight: var(--weight-bold);
  @include set-text-m;
}

.product-list-item__description {
  @include set-text-s;

  overflow: hidden;
  color: var(--color-text-minor);
}
</style>
